import React from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/mode-javascript";
import DeleteIcon from '@mui/icons-material/Delete';
import "ace-builds/src-noconflict/ext-language_tools";
import Confirm from "../../general-components/confirm";
import { postCalculatedField } from "../../Api/vistaDatasets";

const validateFormula = (formula, fields) => {
  // Combinar los nombres de las columnas en una regex
  const fieldRegex = fields.map(item => item.columna).join("|"); // Ejemplo: "ColA|ColB|ColC"
  
  // Expresión regular para validar la fórmula
  const regex = new RegExp(`^(${fieldRegex}|\\d+)(\\s*[+\\-*/]\\s*(${fieldRegex}|\\d+))*$`);

  return regex.test(formula);
};


const FormulaEditor = ({ fields, formulaValueRef }) => {
  React.useEffect(() => {
    console.log('FormulaEditor fields ', fields);
    const langTools = require("ace-builds/src-noconflict/ext-language_tools");
    const ace = require("ace-builds/src-noconflict/ace");

    // Configurar autocompletado personalizado
    const customCompleter = {
      getCompletions: (editor, session, pos, prefix, callback) => {
        const suggestions = fields.map((field) => ({
          caption: field?.columna || '',
          value: field?.columna || '',
          meta: `Campo (${field?.tipo || 'object'})`,
        }));

        callback(null, suggestions);
      },
    };
    langTools.setCompleters([customCompleter]);

    // Configurar ruta de los workers
    ace.config.setModuleUrl(
      "ace/mode/javascript_worker",
      "https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.14/worker-javascript.js"
    );
  }, [fields]);

  const handleChange = (newValue) => {
    console.log("Fórmula actual:", newValue);
    formulaValueRef.current = newValue;
  };

  return (
    <div>
      <Typography variant="body1" gutterBottom>
        Editor de fórmula
      </Typography>
      <AceEditor
        mode="javascript"
        theme="tomorrow"
        name="formula-editor"
        width="100%"
        height="200px"
        fontSize={14}
        enableLiveAutocompletion={true}
        showPrintMargin={false}
        value={formulaValueRef.current}
        onChange={handleChange}
      />
    </div>
  );
};

export default function CalculatedFieldFrom({
  itemCF, loading, closeFn, datasetKey, metadata,
  setLoading, setLoadingMessage, obtenerMetadata
}) {
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [nombre, setNombre] = React.useState(null);
  const formulaValueRef = React.useRef("");

  React.useEffect(() => {
    if (itemCF) {
      setNombre(itemCF.columna);
      formulaValueRef.current = itemCF.formula;
    }
  }, [itemCF]);

  const handleSave = async () => {
    const body = { s3_key: datasetKey?.key, name: nombre, formula: formulaValueRef.current };
    if (!nombre) {
      toast.error('Ingrese un nombre para el campo calculado');
      return;
    } else if (!body.formula) {
      toast.error('Ingrese una fórmula para el campo calculado');
      return;
    } else if (metadata.filter(field => !field.formula).find((field) => field.columna === nombre)) {
      toast.error('Ya existe un campo con ese nombre');
      return;
    } else if (!validateFormula(body.formula, metadata)) {
      toast.error('La fórmula ingresada no es válida');
      return;
    }
    try {
      setLoading(true);
      setLoadingMessage('Guardando campo calculado...');
      const response = await postCalculatedField(body);
      console.log('postCalculatedField response', response);
      if (response.status === 200 || response.status === 201) {
        toast.success('Campo calculado guardado exitosamente');
        obtenerMetadata();
        closeFn();
      } else {
        toast.error('Error al guardar el campo calculado');
      }
    } catch (error) {
      toast.error('Error al guardar el campo calculado');
      console.error('Error al guardar el campo calculado', error);
    } finally {
      setLoading(false);
      setLoadingMessage('');
    }
  };

  const handleDelete = async () => {
    const body = { s3_key: datasetKey?.key, name: nombre, isDelete: true };
    try {
      setLoading(true);
      setLoadingMessage('Eliminando campo calculado...');
      const response = await postCalculatedField(body);
      console.log('postCalculatedField response', response);
      if (response.status === 200 || response.status === 201) {
        toast.success('Campo calculado eliminado exitosamente');
        obtenerMetadata();
        closeFn();
      } else {
        toast.error('Error al eliminar el campo calculado');
      }
    } catch (error) {
      toast.error('Error al eliminar el campo calculado');
      console.error('Error al eliminar el campo calculado', error);
    } finally {
      setLoading(false);
      setLoadingMessage('');
    }
  }

  return (
    <Grid
      item
      container
      alignContent='flex-start'
    >
      {/* Campo Nombre del CF */}
      <Grid item xs={12} sx={{ marginTop: 2 }}>
        <TextField
          id="nombre"
          label="Nombre del campo calculado"
          variant="outlined"
          fullWidth
          value={nombre}
          onChange={(event) => setNombre(event.target.value)}
        />
      </Grid>
      {/* Editor de formula */}
      <Grid item xs={12} sx={{ marginTop: 2 }}>
        <FormulaEditor fields={metadata || []} formulaValueRef={formulaValueRef} />
      </Grid>

      <div style={{ marginTop: '3rem', marginBottom: '1rem', display: 'flex', flex: 1 }}>
        <Button
          variant="contained"
          color='inherit'
          style={{ marginRight: '1rem' }}
          onClick={closeFn}
        >
          Cancelar
        </Button>
        <LoadingButton
          loading={loading}
          disabled={loading}
          onClick={handleSave}
          color='primary'
          variant='contained'
        >
          <span>Guardar</span>
        </LoadingButton>
        {(itemCF && itemCF.columna) && (
          <IconButton color="error" sx={{ marginLeft: 'auto' }} onClick={() => setOpenConfirm(true)}>
            <Tooltip title='Eliminar campo calculado'>
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        )}
      </div>
      <Confirm
        open={openConfirm}
        title={"Eliminar"}
        text={"¿Confirma que desea eliminar este campo calculado?"}
        onConfirm={handleDelete}
        handleCloseProp={() => setOpenConfirm(false)}
      />
    </Grid>
  )

}