import React from "react";
import { Handle, Position } from "react-flow-renderer";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { getNameInitials } from "../../Utils/utilities";
import Tooltip from '@mui/material/Tooltip';
import ProgressBar from "@ramonak/react-progress-bar";
import Chip from '@mui/material/Chip';

const AVATAR_MAX_NUMBER = 4;
const NODE_HEIGHT = 90;

let customNodeStyles = {
  background: "#EBF8FF",
  color: "#000",
  // padding: 2,
  // border: `solid 1px #0F67B1`,
  // borderRadius: 5,
  // width: 220,
  height: NODE_HEIGHT,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
};

const ExtraAvatarsItem = (surplus, responsables) => {
  return (
    <Tooltip
      placement="top"
      title={
        <div>
          {responsables.slice(AVATAR_MAX_NUMBER - 1, responsables.length).map((responsable, index) => {
            return (
              <div key={index}>
                {responsable?.usuario?.nombreCompleto}
              </div>
            )
          })
          }
        </div>
      }
    >
      +{surplus}
    </Tooltip>
  )

}

function SimpleNode({
  data,
  selected,
}) {
  return (
    <div
      style={{
        ...customNodeStyles,
        backgroundColor: data.id === 0 ? "#E5E1DA" : "#EBF8FF",
        border: selected ? "3px dashed #0D9276" : "1px solid #0F67B1",
        // resize: selected ? "none" : "both",
      }}
    >
      <Handle
        type="target"
        position={Position.Top}
        style={{ backgroundColor: 'transparent', border: 'none' }}
        id="inputTarget"
      />
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: "90%", height: "100%" }}>
        <h3 style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '105%' }}>
          {data.nombre}
        </h3>
        {data.responsables && data.responsables.length > 0 &&
          <div style={{ display: 'flex', width: '100%', justifyContent: 'right' }}>
            <AvatarGroup
              renderSurplus={(surplus) => ExtraAvatarsItem(surplus, data.responsables)}
              max={AVATAR_MAX_NUMBER}
              spacing={AVATAR_MAX_NUMBER}
              sx={{
                '& .MuiAvatar-root': {
                  height: 12,
                  width: 12,
                  fontSize: 6,
                }
              }}
            >
              {data.responsables.map((responsable, index) => (
                <Tooltip placement="top" title={responsable?.usuario?.nombreCompleto}>
                  <Avatar key={index} sx={{ bgcolor: '#0F67B1', color: 'white' }}>
                    {getNameInitials(responsable?.usuario?.nombreCompleto)}
                  </Avatar>
                </Tooltip>
              ))}
            </AvatarGroup>
          </div>
        }
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="inputSource"
      />
    </div>
  )
}

function StyledNode1({
  data,
  selected,
}) {
  const { onBottomClick } = data;
  return (
    <div
      style={{
        ...customNodeStyles,
        flexDirection: "column",
        backgroundColor: data.id === 0 ? "#E5E1DA" : data?.cumplido ? "#E0FBE2" : "#EBF8FF",
        boxShadow: selected ? `0px 0px 8px 5px #0F67B1` : `4px 4px 5px 2px rgba(0,0,0,0.75)`,
      }}
    >
      <Handle
        type="target"
        position={Position.Top}
        style={{ backgroundColor: 'transparent', border: 'none' }}
        id="inputTarget"
      />
      <div style={{ display: 'flex', flex: 2, width: '100%', alignItems: 'center' }}>
        <Tooltip
          title={data.nombre}
          placement="top"
          arrow
        >
          <span style={{
            display: '-webkit-box',
            WebkitLineClamp: 2, // Permite hasta 2 líneas
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
            padding: '0.2em 0.5em 0em 0.5em'
          }}>
            {data.nombre}
          </span>
        </Tooltip>
      </div>
      {data?.id > 0 &&
        <>
          <div style={{ display: 'flex', flex: 1, width: '100%' }}>
            {data.responsables && data.responsables.length > 0 ?
              <div style={{ display: 'flex', width: '100%', justifyContent: 'left', padding: '0 0.5em' }}>
                <AvatarGroup
                  renderSurplus={(surplus) => ExtraAvatarsItem(surplus, data.responsables)}
                  max={AVATAR_MAX_NUMBER}
                  spacing={AVATAR_MAX_NUMBER}
                  sx={{
                    '& .MuiAvatar-root': {
                      height: 18,
                      width: 18,
                      fontSize: 8,
                    }
                  }}
                >
                  {data.responsables.map((responsable, index) => (
                    <Tooltip placement="top" title={responsable?.usuario?.nombreCompleto}>
                      <Avatar key={index} sx={{ bgcolor: '#0F67B1', color: 'white' }}>
                        {getNameInitials(responsable?.usuario?.nombreCompleto)}
                      </Avatar>
                    </Tooltip>
                  ))}
                </AvatarGroup>
              </div> :
              <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', color: 'grey' }}>
                Sin responsables
              </span>
            }
          </div>
          <div style={{ display: 'flex', flex: 1, width: '100%', padding: '0 0.5em', alignItems: "center", justifyContent: 'space-between' }}>
            {data?.totalKeyResults ?
              <div style={{ display: 'inline-block', width: '65%' }}>
                <ProgressBar
                  maxCompleted={data?.meta || 0}
                  completed={data?.resultado || 0}
                  customLabel={(data?.resultado || '0')}
                  height="15px"
                  labelAlignment="outside"
                  labelSize="12px"
                  labelColor={data?.cumplido ? "#2fb559" : data?.resultado ? "#3FA2F6" : "#B43F3F"}
                  width="100%"
                  bgColor={data?.cumplido ? "#2fb559" : data?.resultado ? "#3FA2F6" : "#B43F3F"}
                />
              </div> :
              <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', color: 'grey' }}>
                Sin KR
              </span>
            }
            <Chip
              color="primary"
              sx={{
                height: 'auto',
                '& .MuiChip-label': {
                  fontSize: '10px',
                },
              }}
              size="small"
              label={`${data?.totalKeyResults || 0} KR`}
            />
          </div>
        </>
      }
      <Handle
        type="source"
        position={Position.Bottom}
        id="inputSource"
        onClick={onBottomClick}
        style={{
          cursor: 'pointer',
          width: '8px',
          height: '8px',
        }}
      />
    </div>
  );
}

function NodoOKR({ data, selected }) {
  return (<StyledNode1 data={data} selected={selected} />)
}

export default NodoOKR;
export {
  NODE_HEIGHT
}
