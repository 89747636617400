import React from "react";
import { NodoContext } from "../../Context/nodoContext";
import { getMetadataDataset, getDataset } from "../../Api/vistaDatasets";
import { DataGrid, Column, Scrolling, Paging } from 'devextreme-react/data-grid';

export default function DatasetPreview({ datasetKey }) {
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext);

  const [metadata, setMetadata] = React.useState([]);
  const [dataset, setDataset] = React.useState([]);

  React.useEffect(() => {
    obtenerMetadata();
    obtenerDataset();
  }, [datasetKey]);

  const obtenerMetadata = async () => {
    try {
      setLoading(true)
      setLoadingMessage('Obteniendo metadata...')
      const response = await getMetadataDataset(datasetKey?.key);
      console.log('response ', response)
      if (response.status === 200 || response.status === 201) setMetadata(response.body);
    } catch (error) {
      console.error(error);
      setMetadata([]);
    } finally {
      setLoading(false);
    }
  }

  const obtenerDataset = async () => {
    try {
      setLoading(true)
      setLoadingMessage('Obteniendo dataset...')
      const response = await getDataset(datasetKey?.key);
      if (response.status === 200 || response.status === 201) setDataset(response.body);
    } catch (error) {
      console.error(error);
      setDataset([]);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <DataGrid
        dataSource={dataset}
        showBorders={true}
        columnAutoWidth={true}
        rowAlternationEnabled={true}
        height={600}
        width={'100%'}
      >
        <Scrolling mode="both"/>
        <Paging enabled={false} />
        {metadata.map((meta) => (
          <Column
            key={meta.columna}
            dataField={meta.columna}
            caption={`${meta.columna} (${meta.tipo})`}
            dataType={meta.tipo === 'object' ? 'string' : meta.tipo.includes('datetime') ? 'datetime' : 'number'}
            format={meta.tipo.includes('datetime') ? 'yyyy-MM-ddTHH:mm:ss' : undefined}
          />
        ))}
      </DataGrid>
    </div>
  )
}
