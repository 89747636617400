import React from 'react';
import { AccordionOptions } from '../../Indicadores/accordionOptions';
import { AccordionSection } from '../../general-components/accordionSection';
import { Button, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { KRItem } from './krItem';

const OKForm = (props) => {

  const [nombre, setNombre] = React.useState('')
  const [who, setWho] = React.useState('')
  const [does_what, setDoesWhat] = React.useState('')
  const [how_much, setHowMuch] = React.useState('')
  const [responsables, setResponsables] = React.useState([])

  const [expanded, setExpanded] = React.useState({
    definicion: true,
  })

  React.useEffect(() => {
    if (props.isEdit && props.itemOK) {
      setNombre(props.itemOK?.nombre)
      setWho(props.itemOK?.who)
      setDoesWhat(props.itemOK?.does_what)
      setHowMuch(props.itemOK?.how_much)
      setResponsables(props.itemOK?.responsables || [])
    }
  }, [props.isEdit, props.itemOK])

  const handleChange = (panel) => {
    setExpanded({ ...expanded, [panel]: !expanded[panel] })
  }

  const handleContraerTodos = () => {
    const newExpanded = {}
    for (const key in expanded) {
      newExpanded[key] = false
    }
    setExpanded(newExpanded)
  }

  const handleExpandirTodos = () => {
    const newExpanded = {}
    for (const key in expanded) {
      newExpanded[key] = true
    }
    setExpanded(newExpanded)
  }

  const handleSave = () => {
    const data = {
      nombre,
      who,
      does_what,
      how_much,
      responsables: responsables.map((item) => item?.usuario?.id || item.id),
    }
    props.onSave(data)
  }

  return (
    <div style={{ maxHeight: '550px', overflow: 'auto' }}>
      <div style={{ display: 'flex', justifyContent: 'right', margin: '10px' }}>
        <AccordionOptions
          compressHandler={handleContraerTodos}
          expandHandler={handleExpandirTodos}
          expandTitle="Expandir todos"
          compressTitle="Contraer todos"
        />
      </div>

      <AccordionSection
        id='definicion'
        name={'Definición General'}
        description={'Define el objetivo clave y su descripción'}
        expanded={expanded.definicion}
        handleChange={() => handleChange('definicion')}
        children={
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item xs={6}>
              <TextField
                id="nombre"
                label="Nombre"
                variant="outlined"
                value={nombre}
                style={{ width: '100%' }}
                inputProps={{ maxLength: 500 }}
                onChange={(e) => setNombre(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="padre"
                label="Objetivo Padre"
                variant="outlined"
                value={props.objetivoPadre?.nombre || ''}
                style={{ width: '100%' }}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="who"
                label="¿Quién?"
                variant="outlined"
                value={who}
                style={{ width: '100%' }}
                onChange={(e) => setWho(e.target.value)}
                required
                InputProps={{
                  endAdornment: (
                    <Tooltip title="¿A quién impacta el objetivo?">
                      <IconButton size="small">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="does_what"
                label="¿Hace qué?"
                variant="outlined"
                value={does_what}
                style={{ width: '100%' }}
                onChange={(e) => setDoesWhat(e.target.value)}
                required
                InputProps={{
                  endAdornment: (
                    <Tooltip title="¿Que conducta o proceso cambiara/mejorara el objetivo?">
                      <IconButton size="small">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="how_much"
                label="¿Por cuánto?"
                variant="outlined"
                value={how_much}
                style={{ width: '100%' }}
                onChange={(e) => setHowMuch(e.target.value)}
                required
                InputProps={{
                  endAdornment: (
                    <Tooltip title="¿De cuánto será el cambio/mejora?">
                      <IconButton size="small">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Grid>
          </Grid>
        }
      />
      <AccordionSection
        id='responsables'
        name={'Responsables'}
        description={'Colaboradores encargados de cumplir los objetivos clave'}
        expanded={expanded.responsables}
        handleChange={() => handleChange('responsables')}
        children={
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="responsables-select"
                options={props?.usuarios || []}
                getOptionLabel={(option) => option?.nombreCompleto || option?.usuario?.nombreCompleto}
                getOptionKey={(option) => option?.usuario?.id || option.id}
                filterSelectedOptions
                value={responsables}
                onChange={(e, newValue) => {
                  setResponsables(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Responsables"
                    placeholder="Escribe un nombre"
                  />
                )}
              />
            </Grid>
          </Grid>
        }
      />
      <AccordionSection
        id='resultadosClave'
        name={'Resultados Clave'}
        description={'Mediciones para evaluar el cumplimiento del objetivo'}
        expanded={expanded.resultadosClave}
        handleChange={() => handleChange('responsables')}
        children={(
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item container xs={12} justifyContent='flex-end'>
              <Button
                variant="text"
                color='primary'
                onClick={() => props.handleKRSlide(null)}
              >
                Nuevo Resultado Clave
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: 1 }}>
              {props.itemsKR.map((item, index) => {
                return (
                  <KRItem
                    key={index}
                    isNew={item.enviar && !item.id}
                    index={index}
                    name={item.nombre}
                    editAction={() => props.handleKRSlide({ ...item, index })}
                    deleteAction={() => props.onRemoveKR({ ...item, index })}
                    editDisabled={item.indicadorId || (!item.enviar && !item.evaluacion?.esOKR)}
                  >
                  </KRItem>
                )
              })}
              {!props.itemsKR.length && (
                <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                  <Typography variant='body1' color='textSecondary' textAlign='center'>
                    No hay resultados clave
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
        )}
      />

      <div style={{ marginTop: '1rem', float: 'right' }}>
        <Button
          variant="contained"
          color='inherit'
          style={{ marginRight: '1rem' }}
          onClick={props.onClose}
        >
          Cancelar
        </Button>
        <LoadingButton
          loading={props.loading}
          disabled={props.loading}
          onClick={handleSave}
          color='primary'
          variant='contained'
        >
          <span>Guardar</span>
        </LoadingButton>
      </div>
    </div>
  )
}

export default OKForm;
