import React from 'react'
import AbcIcon from '@mui/icons-material/Abc'
import { DEFAULT_DELAY } from '../../Utils/config'
import Modal from '../../general-components/modal'
import SettingsIcon from '@mui/icons-material/Settings';
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import { IconButton, Tooltip, Menu, MenuItem, Chip } from '@mui/material'
import GraficaDSFrom from '../../Favoritos/Datasets/graficaDSForm';
import styles from '../graph.module.css'

export default function ChartDSControls({
  detalleIdProp,
  showValueTag,
  setShowValueTag,
  fullScreen,
  setFullScreen,
  chartType,
  setChartType,
  actualizarVistaTipoGraficaFn,
  handleConfigChance,
  datasetKey,
  configChart,
  isDataLoading,
  setIsDataLoading,
  zoomLock,
  handleZoomLock
}) {

  const [openGraficaDS, setOpenGraficaDS] = React.useState(false)

  const updateChart = (data) => {
    console.log('updateChart ', data)
    handleConfigChance(data)
    setOpenGraficaDS(false)
  }

  if (fullScreen) {
    return (
      <Tooltip title="Cerrar pantalla completa" enterDelay={DEFAULT_DELAY}>
        <IconButton
          color="secondary"
          sx={{ float: 'right' }}
          onClick={() => {
            setFullScreen(false)
          }}
        >
          <CloseFullscreenIcon />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', flex: 1, paddingLeft: 3 }}>
        <Tooltip
          title={'Configuración de la gráfica'}
          enterDelay={DEFAULT_DELAY}
        >
          <IconButton size='large' color='info' onClick={() => setOpenGraficaDS(true)}>
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
        {/* Mostrar tipo de grafica */}
        <Tooltip
          title={zoomLock ? 'Habilitar zoom' : 'Desabilitar zoom'}
          enterDelay={DEFAULT_DELAY}
        >
          <IconButton
            color={showValueTag ? 'primary' : 'default'}
            sx={{ position: 'relative' }}
            onClick={handleZoomLock}
          >
            <ZoomInMapIcon sx={{ color: '#68BDE1', fontSize: 22, zIndex: 1 }} />
            {zoomLock && (
              <div style={{ position: 'absolute', zIndex: 2, display: 'flex' }}>
                <HorizontalRuleIcon sx={{ color: '#DB5F29', fontSize: 35, opacity: 0.9, transform: 'rotate(45deg)' }} />
              </div>
            )}
          </IconButton>
        </Tooltip>
        <Tooltip
          title={showValueTag ? 'Ocultar valores' : 'Mostrar valores'}
          enterDelay={DEFAULT_DELAY}
        >
          <IconButton
            color={showValueTag ? 'primary' : 'default'}
            onClick={() => {
              try {
                actualizarVistaTipoGraficaFn({
                  mostrarEtiquetas: !showValueTag,
                  id: detalleIdProp
                })
                setShowValueTag(!showValueTag)
              } catch (error) {
                console.error(error)
              }
            }}
          >
            <AbcIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Pantalla completa" enterDelay={DEFAULT_DELAY}>
          <IconButton
            color="secondary"
            onClick={() => {
              setFullScreen(true)
            }}
          >
            <OpenInFullIcon />
          </IconButton>
        </Tooltip>
      </div>
      {/* MODAL DE CONFIG */}
      <Modal
        open={openGraficaDS}
        handleClose={() => setOpenGraficaDS(false)}
        title={`Editar gráfico`}
      >
        <GraficaDSFrom
          isOpen={openGraficaDS}
          addFn={updateChart}
          closeFn={() => setOpenGraficaDS(false)}
          datasetKey={{ key: datasetKey }}
          setLoading={() => { }}
          setLoadingMessage={() => { }}
          config={configChart}
        />
      </Modal>
    </div>
  )
}