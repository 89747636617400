import React, { useEffect, useMemo } from 'react'
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source'
import Chart, {
  CommonSeriesSettings,
  Size,
  Tooltip
} from 'devextreme-react/chart'
import { Button } from '@mui/material'
import { toast } from 'react-toastify'
import { LoadingButton } from '@mui/lab'
import { PivotGrid } from 'devextreme-react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { putVistaTab } from '../Api/vistaIndicadorTab.api'
import { FieldChooser } from 'devextreme-react/pivot-grid.js'
import { locale } from "devextreme/localization";
import { format } from 'devextreme/localization';

const customPivotFormatter = (value) => {
  return new Intl.NumberFormat('en-US', { 
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

// locale('en-US');

export function CustomPivot({
  indicadores,
  tabId,
  tipoUsuario,
  pivotState,
  savePivotState,
  mostrarGraph
}) {
  const [loading, setLoading] = React.useState(false)
  const chartRef = React.useRef(null)
  const pivotRef = React.useRef(null)

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const jsonDataSource = []

  indicadores.forEach((indicador) => {
    indicador.resultados.forEach((resultado) => {
      jsonDataSource.push({
        indicador: indicador.nombre,
        fecha: new Date(resultado.fecha),
        valor: resultado.valorNumerico,
        serie: resultado.serie
      })
    })
  })

  const baseConfig = {
    fields: [
      {
        caption: 'Indicador',
        width: isMobile ? 100 : window.innerWidth * 0.40, // Mobile Device 100
        dataField: 'indicador',
        area: 'row',
        sortBySummaryField: 'Total'
      },
      {
        // caption: 'Acumulado',
        dataField: 'valor',
        dataType: 'number',
        summaryType: 'sum',
        area: 'data',
        format: customPivotFormatter,
      },
      {
        // caption: 'Promedio',
        dataField: 'valor',
        dataType: 'number',
        summaryType: 'avg',
        area: 'data',
        format: customPivotFormatter,
      }
    ],
    store: jsonDataSource
  }

  const getPivotState = (pivotState) => {
    const state = JSON.parse(pivotState);
    state.fields = state.fields.map(field => {
      if (field.dataField === 'indicador')
        field.width = isMobile ? 100 : window.innerWidth * 0.40;
      field.format = customPivotFormatter
      return field;
    });
    return state
  }

  const initalConfig = pivotState
    ? { ...getPivotState(pivotState), store: jsonDataSource }
    : baseConfig

  const dataSource = new PivotGridDataSource(initalConfig)

  const pivotTable = useMemo(
    () => (
      <div>
        {mostrarGraph && (
          <Chart ref={chartRef} >
            <Size height={500} width="100%" />
            <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
            <CommonSeriesSettings type="bar" />
          </Chart>
        )}
        <PivotGrid
          id="pivotgrid"
          dataSource={dataSource}
          allowSortingBySummary={true}
          allowFiltering={true}
          allowExpandAll={true}
          showBorders={true}
          showColumnTotals={true}
          showColumnGrandTotals={false}
          showRowTotals={false}
          showRowGrandTotals={false}
          allowSorting={true}
          // texts={{ grandTotal: 'Total' }}
          ref={pivotRef}
        >
          <FieldChooser enabled={true} height={400} allowSearch={true} />
        </PivotGrid>
      </div>
    ),
    [indicadores, tabId]
  )

  useEffect(() => {
    if (!mostrarGraph) return
    const chart = chartRef.current.instance
    const pivotGrid = pivotRef.current.instance

    pivotGrid.bindChart(chart, {
      dataFieldsDisplayMode: 'splitPanes',
      alternateDataFields: true
    })
  }, [mostrarGraph, pivotRef, chartRef])

  function getCleanState() {
    const fields = []
    const fieldsHash = {}
    const pivotGrid = pivotRef.current.instance
    const options = pivotGrid.getDataSource().state()
    options.fields.forEach((field) => {
      if (field.dataField && (!fieldsHash[field.dataField] || field.dataField == "valor")) {
        fields.push(field)
        fieldsHash[field.dataField] = true
      }
    })
    return {
      ...options,
      fields
    }
  }

  async function saveState() {
    if (tipoUsuario !== 'R') {
      setLoading(true)
      const options = getCleanState()
      const pivotState = JSON.stringify(options)
      const modifiedTab = {
        id: tabId,
        pivotState
      }
      const resp = await putVistaTab(modifiedTab)
      if (resp.status !== 200) {
        setLoading(false)
        return toast.error('Ocurrió un error al actualizar la vista')
      }
      setLoading(false)
      savePivotState(tabId, pivotState)
      toast.success('Vista actualizada con éxito')
    }
  }

  return (
    <div>
      {pivotTable}
      {tipoUsuario !== 'R' && !loading && (
        <Button variant="contained" onClick={saveState} sx={{ mt: 2 }}>
          Guardar tabla
        </Button>
      )}
      {tipoUsuario !== 'R' && loading && (
        <LoadingButton loading variant="contained" sx={{ mt: 2 }}>
          Submit
        </LoadingButton>
      )}
    </div>
  )
}

function customizeTooltip(args) {
  const valueText = args.originalValue
  return {
    html: `${args.seriesName} | Total<div class="currency">${valueText}</div>`
  }
}
