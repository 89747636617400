import React from 'react';
import Modal from '../../general-components/modal'
import { Autocomplete, Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getMetadataDataset } from '../../Api/vistaDatasets';

// Modal con el form para crear un nuevo filtro
export default function FilterFormModal({ open, setOpen, dataset, loading, onSave }) {

  const [campo, setCampo] = React.useState(null)
  const [nombre, setNombre] = React.useState(null)
  const [tipo, setTipo] = React.useState('simple')
  const [campos, setCampos] = React.useState([])

  React.useEffect(() => {
    if (open) {
      getMetadata()
    }
  } , [open])

  const getMetadata = async () => {
    try {
      const response = await getMetadataDataset(dataset)
      console.log('response ', response)
      if (response.status === 200 || response.status === 201) setCampos(response.body);
    } catch (error) {
      console.error(error)
    }
  }

  const closeFn = () => {
    setOpen(false)
    setCampo(null)
    setNombre(null)
    setTipo('simple')
  }

  const handleSave = () => {
    const filter = {...campo, nombre, esSimple: tipo === 'simple', range: tipo === 'range', all_values: true}
    onSave(filter)
    closeFn()
  }

  return (
    <Modal
      open={open}
      handleClose={closeFn}
      title={`Filtro de visualización`}
    >
      <Grid container spacing={2} sx={{ paddingX: '1rem', marginY: '1rem' }}>
        <Grid item xs={6}>
          <Autocomplete
            options={campos}
            value={campo}
            getOptionLabel={(option) => `${option.columna} (${option.tipo})` }
            onChange={(event, newValue) => {
              setCampo(newValue)
              setTipo('simple')
            }}
            renderInput={(params) => <TextField {...params} label="Campo" variant='outlined' />}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Nombre"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <RadioGroup
              row
              name="simple"
              value={tipo}
              onChange={(e) => setTipo(e.target.value)}
            >
              <FormControlLabel value="simple" control={<Radio />} label="Valor simple" />
              <FormControlLabel value="multi" control={<Radio />} label="Valor múltiple" disabled={(campo?.tipo || 'object').includes('datetime')} />
              <FormControlLabel value="range" control={<Radio />} label="Rangos" disabled={['object', 'bool'].includes(campo?.tipo || 'object')} />
            </RadioGroup>
          </FormControl>
        </Grid>
        <div style={{ marginTop: '3rem', marginBottom: '1rem'}}>
          <Button
            variant="contained"
            color='inherit'
            style={{ marginRight: '1rem' }}
            onClick={closeFn}
          >
            Cancelar
          </Button>
          <LoadingButton
            loading={loading}
            disabled={loading}
            onClick={handleSave}
            color='primary'
            variant='contained'
          >
            <span>Guardar</span>
          </LoadingButton>
        </div>
      </Grid>
    </Modal>
  )
}
