import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import styles from './style.module.css'
import { updatetipoVista } from '../Api/vistaIndicador.api'
import { useEffect, useRef } from 'react'
import { Badge, Divider, Paper } from '@mui/material'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import CommentIcon from '@mui/icons-material/Comment'
import { TextField, Tooltip } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import Comentario from './comentario'
import { toast } from 'react-toastify'
import { AuthContext } from '../Context/authContext'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { FileUpload } from '../general-components/fileUpload'
import DescripcionTab from './descripcionTab'
import { putModifyOrder } from '../Api/vistaIndicadorTab.api'
import DescripcionTabVista from './descripcionTabVista'
import { CustomizedGraph } from '../Chart/graph'
import { CustomPivot } from '../PivotGrid/CustomPivot'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Confirm from "../general-components/confirm";
import { useNavigate } from 'react-router-dom'
import ZoomDialog from '../Chart/zoomDialog'
import GraphDS from '../Chart/Datasets/graphDS'
import SettingsIcon from '@mui/icons-material/Settings';
import TemporaryDrawer from '../general-components/temporary-drawer'
import { IndicadoresAsignadosTable } from '../Evaluaciones/indicadoresAsignados'
import TotalKPIModal from './totalKPIModal'
import { getGraphicData } from '../Api/graphics.api'
import { PeriodoContext } from '../Context/periodoContext'
import { descomprimirPayload } from '../Utils/utilities'
import { graphDataset } from '../Api/vistaDatasets'

const RESUMEN_TAB = 0

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '3px 0 3px  0' }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

function FavoritosTabs({
  tabs,
  leyendas,
  resultadosEvaluacion,
  informe,
  setDescripcionTab,
  esInforme = false,
  esTableroPersonal = false,
  grabarMensaje,
  updateVista,
  setTabs = () => { },
  setPropsTabIndex = () => { },
  esBorrador = false,
  vista,
  fechaInicioConfirmada,
  fechaFinConfirmada,
  semanaZafra,
}) {
  const [tabIndexPre, setTabIndexPre] = React.useState(0)
  const [tabIndex, setTabIndex] = React.useState(0)
  const [totalesTab, setTotalesTab] = React.useState([])
  const [openCommentsDrawer, setOpenCommentsDrawer] = React.useState(false)
  const [openFilesDrawer, setOpenFilesDrawer] = React.useState(false)
  const [comentariosGraficaTab, setComentariosGraficaTab] = React.useState([])
  const [openConfirmNavegacion, setOpenConfirmNavegacion] = React.useState(false);
  const [rutaNavegacion, setRutaNavegacion] = React.useState(null);
  // const [avisoZoom, setAvisoZoom] = React.useState(false);
  const [openTotalKPI, setOpenTotalKPI] = React.useState(false)
  const [indicadorItem, setIndicadorItem] = React.useState(null)

  const { getSelected, periodos } = React.useContext(PeriodoContext)
  const { getToken } = React.useContext(AuthContext)
  const navigate = useNavigate()
  const inputElement = useRef()

  const autenticado = getToken() !== null
  let mensaje = ''

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // useEffect(() => {
  //   const isFirstVisit = !localStorage.getItem('avisoZoom');
  //   if (isFirstVisit) {
  //     setTimeout(() => {
  //       setAvisoZoom(true);
  //     }, 1000);
  //     localStorage.setItem('avisoZoom', 'true');
  //   }
  // }, []);

  useEffect(() => {
    if (vista?.filtros) calcularTotales(totalesTab)
  }, [vista?.filtros])

  const handleTabChange = (newValue) => {
    setPropsTabIndex(newValue)
    setTabIndex(newValue)
    setOpenCommentsDrawer(false)
  }

  const handleTabChangePreSave = async (event, newValue) => {
    // Guardamos el comentario
    if (esBorrador || esInforme) {
      setTabIndexPre(newValue);
    } else {
      handleTabChange(newValue)
    }
  }

  const actualizarVistaTipoGraficaFn = async (object) => {
    if (object === null) {
      return
    }

    if (vista?.tipoUsuario == 'R') {
      return
    }

    try {
      const response = await updatetipoVista(object)
    } catch (error) {
      toast.error('Error al actualizar la vista')
    }
  }

  useEffect(() => {
    let totalesTabCopy = []
    let comentariosTemp = [...comentariosGraficaTab]
    tabs.forEach((tab) => {
      tab.indicadores.forEach((indicador) => {
        const comentariosGraficas = obtenerComentarios(indicador)

        const totalIndicador = {
          tabId: tab.id,
          indicadorId: indicador.id,
          indicadorNombre: indicador.nombre,
          visible: indicador.mostrarResumen,
          detalleId: indicador.detalleId,
          totalText: '9000',
          color: '#3498db',
          configGrafica: typeof indicador.configGrafica === 'string' ? JSON.parse(indicador.configGrafica) : indicador.configGrafica,
          porDataset: indicador.porDataset
        }

        totalesTabCopy = [...totalesTabCopy, totalIndicador]
        comentariosTemp = [...comentariosTemp, ...comentariosGraficas]
      })
      // El estado se modifica al final por temas de performance
      setComentariosGraficaTab([...comentariosTemp])
      calcularTotales(totalesTabCopy)
    })
  }, [tabs])

  function obtenerComentarios({ resultados, indicadorNombre }) {
    let comentarios = []

    resultados.forEach((elem, index) => {
      //Extrae los comentarios de el indicador
      try {
        if (Array.isArray(elem.comentarios) && elem.comentarios.length > 0) {
          let commentObj = elem.comentarios.map((comment) => {
            return {
              fechaCreacion: comment.annotationDate,
              mensaje: indicadorNombre + ':\n' + comment.comment,
              autor: comment.usuario,
              indicadorNombre: indicadorNombre,
              tabId: elem.tab,
              informeId: informe.id
            }
          })

          comentarios = [...comentarios, ...commentObj]
        }
      } catch (error) {
        console.error(error)
      }
    })

    return comentarios
  }

  const insertarMensaje = async (tabId) => {
    if (mensaje.trim() === '') {
      return
    }

    const json = {
      informeId: informe.id,
      tabId: tabId,
      mensaje: mensaje
    }

    await grabarMensaje(json)
    aumentarContadorComentarios(1, tabId)
    mensaje = ''
    clearAndFocus()
  }

  const setMessage = (value) => {
    mensaje = value
  }

  const clearAndFocus = () => {
    inputElement.current.value = null
    inputElement.current.focus()
  }

  const addComentarioGrafica = async (comment) => {
    //Encuentra nombre del indicador
    const tab = tabs.find((x) => x.id === comment.tabId)
    const indicador = tab.indicadores.find((x) => x.id === comment.indicador)

    const fila = {
      fechaCreacion: comment.annotationDate,
      mensaje: indicador.nombre + ':\n' + comment.comment,
      autor: comment.usuario,
      indicadorNombre: indicador.nombre,
      tabId: comment.tabId,
      informeId: informe.id
    }
    let comentariosGraficas = [...comentariosGraficaTab]
    comentariosGraficas.push(fila)

    //ordena
    comentariosGraficas.sort((x, y) => {
      let xx = new Date(x.fechaCreacion)
      let yy = new Date(y.fechaCreacion)
      return x.indicadorNombre.localeCompare(y.indicadorNombre) || yy - xx
    })
    setComentariosGraficaTab(comentariosGraficas)
  }

  function modifyOrder(idOrigen, idDestino) {
    if (idOrigen === idDestino) return
    putModifyOrder({ idOrigen, idDestino }).then((response) => {
      if (response.status === 200) {
        updateVista()
        toast.success('Orden modificado')
      } else {
        toast.error('No se pudo modificar el orden')
      }
    })
  }

  function contarComentarios(tabIndex) {
    const tab = tabs.find((x, index) => index === (esTableroPersonal ? tabIndex - 1 : tabIndex))

    if (tab) {
      let innerComments = 0
      tab.indicadores.forEach((indicador) => {
        indicador.resultados.forEach((resultado) => {
          innerComments += resultado.comentarios.length
        })
      })
      return tab.comentarios + innerComments
    }
    return 0
  }

  function contarArchivos() {
    const tab = tabs.find((x, index) => index === (esTableroPersonal ? tabIndex - 1 : tabIndex))
    if (tab) {
      return tab.archivos
    }
    return 0
  }

  function aumentarContadorArchivos(cantidad, tabId) {
    let tab = tabs.find((x) => x.id === tabId)
    tab.archivos += cantidad
    let newTabs = [...tabs]
    setTabs(newTabs)
  }

  function aumentarContadorComentarios(cantidad, tabId) {
    let tab = tabs.find((x) => x.id === tabId)
    tab.comentarios += cantidad
    let newTabs = [...tabs]
    setTabs(newTabs)
  }

  function savePivotState(tabId, pivotState) {
    const tabsCopy = [...tabs]
    let tab = tabsCopy.find((x) => x.id === tabId)
    tab.pivotState = pivotState
    setTabs(tabsCopy)
  }

  function getArraySeriesOcultas(indicador) {
    let seriesOcultas = []
    if (indicador.seriesOcultas) {
      seriesOcultas = indicador.seriesOcultas.split(',')
    }
    return seriesOcultas
  }

  function getLeyendasVisibles(indicador) {
    let _leyendas = leyendas.filter((leyenda) => !leyenda.esSerie)
    if (indicador.seriesNombres) {
      _leyendas = leyendas.filter((leyenda) =>
        indicador.seriesNombres.some((serie) => leyenda.value.includes(serie))
      )
      // Eliminar leyendas con nombres duplicados
      _leyendas = _leyendas.reduce((acc, current) => {
        if (!acc.some(item => item.value === current.value)) acc.push(current);
        return acc;
      }, []);
    }
    return _leyendas
  }

  function handleOpenTotalKPI(item = null) {
    setOpenTotalKPI(true)
    setIndicadorItem(item)
  }

  function handleCloseTotalKPI() {
    setOpenTotalKPI(false)
    setIndicadorItem(null)
  }

  async function handleSaveTotalKPI(config, indicador) {
    // Buscar el indicador en totalesTab
    let totalesTabCopy = [...totalesTab]
    const totalIndicador = totalesTabCopy.find((total) => total.detalleId === indicador.detalleId)
    if (indicador.porDataset) {
      totalIndicador.configGrafica.resumen = config
    } else {
      totalIndicador.configGrafica = config
    }
    calcularTotales(totalesTabCopy)

    // Actualizar en BD
    try {
      console.log('KPI indicador ', indicador)
      console.log('KPI totalIndicador.configGrafica ', totalIndicador.configGrafica)
      actualizarVistaTipoGraficaFn({
        id: indicador.detalleId,
        configGrafica: totalIndicador.configGrafica,
      })
    } catch (error) { console.error(error) }
    handleCloseTotalKPI()
  }

  const getPeriodosLeyendas = () => {
    if (getSelected().length && !esInforme)
      return getSelected()

    const _titulos = leyendas.filter((titulo) => !titulo.esSerie && titulo.value.toLowerCase().includes('periodo') && !titulo.value.toLowerCase().includes('meta'))
    const _periodos = periodos.filter((periodo) => _titulos.map((titulo) => titulo.value).includes(periodo.nombre))
    return _periodos.map(item => item.id)
  }

  const calcularTotales = async (totalesTab) => {
    // Obtener resultados
    const tabId = tabs[tabIndex].id;
    let totalesTabCopy = [...totalesTab];
  
    const promises = totalesTabCopy.map(async (element) => {
      element.totalText = '';
      element.color = '#3498db';
      if (!element.visible) return;
      let config = element.configGrafica;
      if (element.porDataset) config = config.resumen || {};

      if (element.tabId === tabId) {
        try {
          let responseD = null;
          if (element.porDataset) {
            const body = {
              s3_key: vista?.datasetS3,
              config: {...config, tipoGrafica: "kpi"},
              visualFilters: vista?.filtros || [],
            }
            const response = await graphDataset(body)
            responseD = response.body
          } else {
            const body = {
              periodos: getPeriodosLeyendas(),
              indicador: element.indicadorId,
              con_meta: config?.con_meta,
              operacion: config?.operacion,
            };
            const response = await getGraphicData('kpi', body);
            responseD = descomprimirPayload(response.data)
          }
          if (responseD && responseD.resultados) {
            const valor = responseD.resultados.valorNumerico || 0;
            const meta = responseD.resultados.meta || 0;
            const cumplio = responseD.resultados.cumplio;
            element.totalText = `${valor % 1 === 0 ? valor.toFixed(0) : valor.toFixed(2)}`;
            if (config?.con_meta) {
              element.totalText = `${valor % 1 === 0 ? valor.toFixed(0) : valor.toFixed(2)} / ${meta % 1 === 0 ? meta.toFixed(0) : meta.toFixed(2)}`;
              element.color = cumplio ? '#27ae60' : '#c0392b';
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
  
    await Promise.all(promises);
    setTotalesTab(totalesTabCopy);
  };

  return (
    <Box sx={{ width: '100%', height: 'auto' }}>
      {/* <ZoomDialog open={avisoZoom} onClose={() => setAvisoZoom(false)} /> */}

      {esInforme && tabIndex !== (esTableroPersonal ? RESUMEN_TAB : -1) && (
        <div style={{ marginTop: '10px' }}>
          <Tooltip title={'Mostrar comentarios'} enterDelay={100}>
            <IconButton
              aria-label="comentarios"
              onClick={() => setOpenCommentsDrawer(true)}
            >
              <Badge badgeContent={contarComentarios(tabIndex)} color="error">
                <CommentIcon color={contarComentarios(tabIndex) ? 'primary' : 'inherit'} />
              </Badge>
            </IconButton>
          </Tooltip>

          <Tooltip title={'Mostrar archivos'} enterDelay={100}>
            <IconButton
              aria-label="archivos"
              onClick={() => setOpenFilesDrawer(true)}
            >
              <Badge badgeContent={contarArchivos()} color="error">
                <AttachFileIcon color={contarArchivos() ? 'primary' : 'action'} />
              </Badge>
            </IconButton>
          </Tooltip>
        </div>
      )}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChangePreSave}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {(esTableroPersonal) && Array.isArray(tabs) && (
            <Tab
              key={'item27287sbbhkk'}
              label={'Resumen'}
              {...a11yProps(tabs.length)}
              draggable={false}
            />
          )}
          {tabs.map((item, index) => (
            <Tab
              key={item.id}
              label={
                <Badge
                  color="error"
                  badgeContent={esInforme && tabIndex !== (esTableroPersonal ? RESUMEN_TAB : -1) ? contarComentarios(index) : null}
                >
                  {item.nombre}
                </Badge>
              }
              {...a11yProps(index)}
              draggable={!esInforme}
              onDragStart={(e) => {
                e.dataTransfer.setData(
                  'tab',
                  JSON.stringify({ id: item.id, nombre: item.nombre })
                )
              }}
              onDragOver={(e) => {
                e.preventDefault()
              }}
              onDragLeave={(e) => {
                e.preventDefault()
              }}
              onDrop={(e) => {
                e.preventDefault()
                const data = JSON.parse(e.dataTransfer.getData('tab'))
                modifyOrder(data.id, item.id)
              }}
            />
          ))}
        </Tabs>
      </Box>
      <>
        {esTableroPersonal && Array.isArray(tabs) && (
          <TabPanel value={tabIndex} index={0}>
            <div className={styles['content-style']} style={{ margin: '20px' }}>
              <h1>Resultados actuales:</h1>
              <IndicadoresAsignadosTable
                dataSource={resultadosEvaluacion}
                setDatasource={() => { }}
                mostrarSoloDesempeno={true}
              />
            </div>
          </TabPanel>
        )}
        {tabs.map((tab, i) => (
          <TabPanel
            key={tab.id}
            value={tabIndex}
            index={
              (esTableroPersonal) &&
                Array.isArray(tabs)
                ? i + 1
                : i
            }
          >
            <div className={styles['content-style']}>
              {esInforme && (
                <DescripcionTab
                  tab={tab}
                  informeId={informe.id}
                  autenticado={autenticado}
                  setDescripcionTab={setDescripcionTab}
                  handleTabChange={handleTabChange}
                  tabIndex={tabIndex}
                  tabIndexPre={tabIndexPre}
                />
              )}
              {esBorrador && (
                <DescripcionTabVista
                  tab={tab}
                  vistaId={vista.id}
                  setDescripcionTab={setDescripcionTab}
                  handleTabChange={handleTabChange}
                  tabIndex={tabIndex}
                  tabIndexPre={tabIndexPre}
                />
              )}
              {!tab.is_embedded &&
                <div
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginBottom: '10px'
                  }}
                >
                  {/* Cinta de Cajas KPI */}
                  <Grid container spacing={3} sx={{ marginTop: '0px' }}>
                    {totalesTab.filter((resumenCard) => resumenCard.tabId === tab.id && resumenCard.visible).map((element, index) => (
                      <Grid item xs={6} sm={6} md={4} lg={2} key={index}>
                        <Paper
                          style={{
                            backgroundColor: element.color,
                            width: 'auto',
                            height: 150,
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            position: 'relative',
                          }}
                        >
                          <IconButton
                            style={{
                              position: 'absolute',
                              left: 0,
                              top: 0,
                              display: (vista?.tipoUsuario == 'R' || esInforme) ? 'none' : 'block'
                            }}
                            title='Configurar'
                            onClick={() => handleOpenTotalKPI(element)}
                          >
                            <SettingsIcon color='action' />
                          </IconButton>
                          <div
                            style={{
                              fontSize: '1.5em',
                              fontWeight: 'bold',
                              color: '#eee'
                            }}
                          >
                            {element.configGrafica ? element.totalText : 'N/A' }
                          </div>
                          <div style={{ color: '#eee' }}>
                            {element.configGrafica?.nombre ? element.configGrafica.nombre : element.indicadorNombre}
                          </div>
                        </Paper>
                      </Grid>
                    )
                    )}
                  </Grid>
                </div>}
              <section
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                }}
              >
                {!tab.is_embedded && tab.indicadores.map((indicador, indexChart) => {
                  const _leyendas = getLeyendasVisibles(indicador)

                  if (indicador.porDataset) {
                    return (
                      <div
                        key={`${indicador.id}-${indexChart}`}
                        style={{
                          display: indicador.mostrarGrafica ? 'flex' : 'none'
                        }}
                        className={styles['graph-container']}
                      >
                        <GraphDS
                          argumentRange={indicador.argumentRange}
                          valueRange={indicador.valueRange}
                          detalleIdProp={indicador.detalleId}
                          configGrafica={indicador.configGrafica}
                          visualFilters={vista?.filtros || []}
                          dataset={vista?.datasetS3}
                          mostrarEtiquetasProp={indicador.mostrarEtiquetas}
                          actualizarVistaTipoGraficaFn={actualizarVistaTipoGraficaFn}
                          showToolButtons={!esInforme}
                          allowComments={esInforme}
                          seriesOcultasProp={getArraySeriesOcultas(indicador)}
                          agregarListaComentarios={addComentarioGrafica}
                          fechaInicioConfirmada={fechaInicioConfirmada}
                          fechaFinConfirmada={fechaFinConfirmada}
                          maxWidth={isMobile ? '100%' : (
                            tab.indicadores.length === 1
                              ? window.innerWidth - 20
                              : (window.innerWidth - 80) / 2
                          )}
                          minWidth={
                            tab.indicadores.length === 1
                              ? window.innerWidth - 320
                              : (window.innerWidth - 380) / 2
                          }
                          height={
                            tab.indicadores.length === 1
                              ? window.innerHeight - 200
                              : 500
                          }
                        />
                      </div>
                    )
                  }
                  return (
                    <div
                      key={`${indicador.id}-${indexChart}`}
                      style={{
                        display: indicador.mostrarGrafica ? 'flex' : 'none'
                      }}
                      className={styles['graph-container']}
                    >
                      <CustomizedGraph
                        argumentRange={indicador.argumentRange}
                        valueRange={indicador.valueRange}
                        indexChart={indexChart}
                        titulos={_leyendas}
                        periodosGuardados={leyendas.filter((leyenda) => !leyenda.esSerie)}
                        esAgrupadoSeries={indicador.seriesNombres && indicador.seriesNombres.length}
                        resultados={indicador.resultados}
                        etiquetaEjeX={indicador.etiquetaEjeX}
                        graficarEjeXDiscreto={indicador.graficarEjeXDiscreto}
                        formatoFecha={indicador.formatoFecha}
                        titulo={indicador.nombre}
                        nombrePersonalizado={indicador.nombrePersonalizado}
                        indicadorId={indicador.id}
                        indicadorPrevId={indicador.prevId}
                        operacionIntegracion={indicador.operacionIntegracion}
                        metaInversa={indicador.metaInversa}
                        maxWidth={isMobile ? '100%' : (
                          tab.indicadores.length === 1
                            ? window.innerWidth - 20
                            : (window.innerWidth - 80) / 2
                        )}
                        minWidth={
                          tab.indicadores.length === 1
                            ? window.innerWidth - 320
                            : (window.innerWidth - 380) / 2
                        }
                        height={
                          tab.indicadores.length === 1
                            ? window.innerHeight - 200
                            : 500
                        }
                        seriesNombres={indicador.seriesNombres || []}
                        tipoGraficaProp={indicador.tipoGrafica}
                        tipoVistaProp={indicador.tipoVista}
                        detalleIdProp={indicador.detalleId}
                        mostrarEtiquetasProp={indicador.mostrarEtiquetas}
                        agrupadorGraficaProp={indicador.agrupadorGrafica}
                        seriesOcultasProp={getArraySeriesOcultas(indicador)}
                        showAnalysisProp={indicador.mostrarAnalisis}
                        analysisDataProp={indicador.analysisData}
                        actualizarVistaTipoGraficaFn={
                          actualizarVistaTipoGraficaFn
                        }
                        showToolButtons={!esInforme}
                        allowComments={esInforme}
                        agregarListaComentarios={addComentarioGrafica}
                        fechaInicioConfirmada={fechaInicioConfirmada}
                        fechaFinConfirmada={fechaFinConfirmada}
                        onDoubleClick={() => {
                          setOpenConfirmNavegacion(true);
                          setRutaNavegacion(`${indicador.Nodo_Indicador}/indicadores?indicadorId=${indicador.id}`);
                        }}
                        semanaZafra={semanaZafra}
                      />
                    </div>
                  )
                })}
                {tab.is_embedded &&
                  <div className='h_iframe'>
                    <iframe
                      src={tab.embedded_link}
                      frameborder="0"
                    />
                  </div>
                }
              </section>
              <section>
                {/* Seccion del Pivot*/}
                {(tab.mostrarPivote || tab.mostrarPivoteGraph) && (
                  <div
                    style={{
                      width: '100%',
                      justifyContent: 'center',
                      padding: `0px ${isMobile ? '1rem' : '10%'}`
                    }}
                  >
                    <CustomPivot
                      indicadores={tab.indicadores}
                      tabId={tab.id}
                      tipoUsuario={esInforme ? 'R' : vista?.tipoUsuario}
                      pivotState={tab.pivotState}
                      mostrarGraph={tab.mostrarPivoteGraph}
                      savePivotState={savePivotState}
                    />
                  </div>
                )}
              </section>
              {esInforme && (
                <>
                  <TemporaryDrawer
                    position={'right'}
                    title="Comentarios"
                    show={openCommentsDrawer}
                    handleClose={() => setOpenCommentsDrawer(false)}
                    hideBackdrop={false}
                  >
                    {autenticado && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '20px'
                        }}
                      >
                        <TextField
                          label="Comentario..."
                          variant="outlined"
                          style={{ width: '90%' }}
                          inputProps={{ ref: inputElement }}
                          onChange={(e) => setMessage(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key.toLocaleLowerCase() === 'enter') {
                              insertarMensaje(tab.id)
                            }
                          }}
                          autoFocus
                        />
                        <IconButton
                          color="primary"
                          aria-label="Post comment"
                          component="label"
                          onClick={() => insertarMensaje(tab.id)}
                        >
                          <SendIcon />
                        </IconButton>
                      </div>
                    )}

                    {informe.mensajes
                      ?.filter((me) => me.tabId === tab.id)
                      .map((message, index) => (
                        <Comentario data={message} key={index} />
                      ))}

                    <Divider style={{ marginTop: 20 }} />
                    <Box>
                      <Typography variant="h6" gutterBottom component="div">
                        Comentarios sobre indicadores
                      </Typography>
                    </Box>

                    {comentariosGraficaTab
                      ?.filter((me) => me.tabId === tab.id)
                      .map((message, index) => (
                        <Comentario data={message} key={index} />
                      ))}
                  </TemporaryDrawer>
                  <TemporaryDrawer
                    position={'right'}
                    title="Archivos"
                    show={openFilesDrawer}
                    handleClose={() => setOpenFilesDrawer(false)}
                    hideBackdrop={false}
                  >
                    <FileUpload
                      tabId={tab.id}
                      informeId={informe.id}
                      autenticado={autenticado}
                      aumentarContador={aumentarContadorArchivos}
                    />
                  </TemporaryDrawer>
                </>
              )}
            </div>
          </TabPanel>
        ))}
      </>
      <Confirm
        open={openConfirmNavegacion}
        title={"Confirmar navegación"}
        text={"¿Confirma que deseas ver el indicador seleccionado en el árbol de procesos?"}
        onConfirm={() => {
          setOpenConfirmNavegacion(false);
          navigate(`/home/procesos/${rutaNavegacion}`);
        }}
        handleCloseProp={() => {
          setOpenConfirmNavegacion(false);
          setRutaNavegacion(null);
        }}
      />
      <TotalKPIModal
        open={openTotalKPI}
        closeFn={handleCloseTotalKPI}
        indicador={indicadorItem}
        handleSaveTotalKPI={handleSaveTotalKPI}
        dataset={vista?.datasetS3}
      />
    </Box>
  )
}

export { FavoritosTabs }
