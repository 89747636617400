import { HOST } from "../Utils/config";
import { deleteRequest, getRequest, postRequest, putRequest } from "./requests";

async function getInforme(informeId) {
  return await getRequest(`${HOST}/api/v1/vistainforme/${informeId}`);
}

async function getInformePublico(informeId) {
  return await getRequest(`${HOST}/api/v1/vistainforme/publico/${informeId}`);
}

async function getInformes(vistaId) {
  console.log("Obteniendo informes...")
  return await getRequest(`${HOST}/api/v1/vistainforme/vista/${vistaId}`);
}

async function getFiles(informeId, tabId) {
  return await getRequest(
    `${HOST}/api/v1/vistainforme/${informeId}/tab/${tabId}/files`
  );
}

async function createInforme(informe, fechaIni, fechaFin, offset, periodo, semanaZafra=null) {
  let queryParams = `fechaIni=${fechaIni}&fechaFin=${fechaFin}&offset=${offset}&periodo=${periodo}&completo=1`;
  if (semanaZafra) queryParams += `&semanaZafra=${semanaZafra}`;
  return await postRequest(`${HOST}/api/v1/vistainforme?${queryParams}`, informe);
}

async function createFile(fileMetadata) {
  return await postRequest(`${HOST}/api/v1/vistainforme/file`, fileMetadata);
}

async function updateVisibilidad(data) {
  return await putRequest(`${HOST}/api/v1/vistainforme/visibilidad`, data);
}

async function updateResume(data) {
  return await putRequest(`${HOST}/api/v1/vistainforme/updateResume`, data);
}

async function updateDescripcionTab(data) {
  return await putRequest(`${HOST}/api/v1/vistainforme/updateDescripcionTab`, data);
}

async function addAnnotation(data) {
  return await putRequest(`${HOST}/api/v1/vistainforme/annotations`, data);
}

async function deleteInforme(vistaId) {
  return await deleteRequest(`${HOST}/api/v1/vistainforme/${vistaId}`);
}

async function getMessages(informeId) {
  return await getRequest(
    `${HOST}/api/v1/vistainformecomentariotab/${informeId}`
  );
}

async function getMessagesPublic(informeId) {
  return await getRequest(
    `${HOST}/api/v1/vistainformecomentariotab/getmessagespublic/${informeId}`
  );
}

async function createMessage(data) {
  return await postRequest(`${HOST}/api/v1/vistainformecomentariotab`, data);
}

async function sendEmail(informeId) {
  return await postRequest(
    `${HOST}/api/v1/vistainforme/enviarCorreo/${informeId}`, {}
  );
}

export {
  getInforme,
  getInformes,
  getInformePublico,
  getFiles,
  createInforme,
  createFile,
  addAnnotation,
  deleteInforme,
  updateResume,
  updateDescripcionTab,
  getMessages,
  updateVisibilidad,
  createMessage,
  getMessagesPublic,
  sendEmail,
};
